// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuelles-js": () => import("./../../../src/pages/aktuelles.js" /* webpackChunkName: "component---src-pages-aktuelles-js" */),
  "component---src-pages-archiv-index-js": () => import("./../../../src/pages/archiv/index.js" /* webpackChunkName: "component---src-pages-archiv-index-js" */),
  "component---src-pages-archiv-koenigsschiessen-2010-js": () => import("./../../../src/pages/archiv/koenigsschiessen/2010.js" /* webpackChunkName: "component---src-pages-archiv-koenigsschiessen-2010-js" */),
  "component---src-pages-archiv-koenigsschiessen-2011-js": () => import("./../../../src/pages/archiv/koenigsschiessen/2011.js" /* webpackChunkName: "component---src-pages-archiv-koenigsschiessen-2011-js" */),
  "component---src-pages-archiv-koenigsschiessen-2012-js": () => import("./../../../src/pages/archiv/koenigsschiessen/2012.js" /* webpackChunkName: "component---src-pages-archiv-koenigsschiessen-2012-js" */),
  "component---src-pages-archiv-koenigsschiessen-2013-js": () => import("./../../../src/pages/archiv/koenigsschiessen/2013.js" /* webpackChunkName: "component---src-pages-archiv-koenigsschiessen-2013-js" */),
  "component---src-pages-archiv-meisterschaft-dm-lg-2021-js": () => import("./../../../src/pages/archiv/meisterschaft/dm-lg-2021.js" /* webpackChunkName: "component---src-pages-archiv-meisterschaft-dm-lg-2021-js" */),
  "component---src-pages-archiv-mitgliederversammlung-2015-js": () => import("./../../../src/pages/archiv/mitgliederversammlung/2015.js" /* webpackChunkName: "component---src-pages-archiv-mitgliederversammlung-2015-js" */),
  "component---src-pages-archiv-mitgliederversammlung-2019-js": () => import("./../../../src/pages/archiv/mitgliederversammlung/2019.js" /* webpackChunkName: "component---src-pages-archiv-mitgliederversammlung-2019-js" */),
  "component---src-pages-archiv-mitgliederversammlung-2020-js": () => import("./../../../src/pages/archiv/mitgliederversammlung/2020.js" /* webpackChunkName: "component---src-pages-archiv-mitgliederversammlung-2020-js" */),
  "component---src-pages-archiv-mitgliederversammlung-2021-js": () => import("./../../../src/pages/archiv/mitgliederversammlung/2021.js" /* webpackChunkName: "component---src-pages-archiv-mitgliederversammlung-2021-js" */),
  "component---src-pages-archiv-wanderpokal-2015-js": () => import("./../../../src/pages/archiv/wanderpokal/2015.js" /* webpackChunkName: "component---src-pages-archiv-wanderpokal-2015-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-termine-js": () => import("./../../../src/pages/termine.js" /* webpackChunkName: "component---src-pages-termine-js" */),
  "component---src-pages-training-js": () => import("./../../../src/pages/training.js" /* webpackChunkName: "component---src-pages-training-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-verein-js": () => import("./../../../src/pages/verein.js" /* webpackChunkName: "component---src-pages-verein-js" */)
}

